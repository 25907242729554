import axios from 'axios';
import Swal from 'sweetalert2';

import { useCurrentUserStore } from '@/stores/currentUser.js';
import { useToast } from "vue-toastification";

import UIVersion from '@/lib/ui-version.js';

const apiBaseURL = import.meta.env.MODE == "development" ? "http://" + window.location.hostname + ":8123" : import.meta.env.VITE_API_BASE_URL;

var hasAlreadyAskedForUpdate = false;
const validateUIVersion = async (axiosResponse) => {
    if (!axiosResponse.data || !axiosResponse.data.ui) return;
    if (axiosResponse.data.ui == UIVersion) return;
    if (hasAlreadyAskedForUpdate) return;
    hasAlreadyAskedForUpdate = true;
    const result = await Swal.fire({
        title: "Aktualisierte Oberfläche",
        html: `
        <div class="text-start">
            <p>Für die Datenbank gibt es eine neue Version.</p>
            <p>Diese Oberfläche kann noch weiter funktionieren, u.U. kann es allerdings auch zu schwerwiegenden Problemen kommen..</p>
            <p class="mb-0">Bitte lade die Oberfläche neu, um diese zu aktualisieren..</p>
        </div>
        `,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonColor: '#198754',
        denyButtonColor: '#DC3545',
        confirmButtonText: "Aktualisieren",
        denyButtonText: "Später",
        showLoaderOnConfirm: true,
        preConfirm: async (pno) => {
            await exp.loadStore();
            exp.toast.success("UI wird aktualisiert, einen Moment bitte...\nDies kann ein paar Sekunden dauern", {
                position: "bottom-center",
                timeout: 2500,
                closeOnClick: true,
            });
            await new Promise((resolve) => setTimeout(resolve, 2000));
            window.location.reload(true);
        }
    });
    if (!result.isConfirmed) return;
};

const exp = {
    userStore: null,
    toast: null,
    apiBaseURL,

    /**
     * Ggf. laden des Stores; wenn bisher nicht geschehen
     */
    async loadStore() {
        if (this.toast == null) this.toast = useToast();
        if (this.userStore != null) return;
        this.userStore = await useCurrentUserStore();
    },

    /**
     * Führt eine GET-Abfrage durch
     * 
     * @param {string} URL 
     * @returns {Promise<object>}
     */
    async get(URL) {
        try {
            await this.loadStore();
            const headers = this.userStore.currentSessionToken != null ? { 'X-Access-Token': this.userStore.currentSessionToken } : {};
            var ret = await axios.get(apiBaseURL + URL, { headers });
            await validateUIVersion(ret);
            return ret.data;
        } catch (e) {
            var errorMessage = "Unbekannter Fehler bei Server-Abfrage.";
            if (e.response && e.response.data && e.response.data.message) {
                errorMessage = "Server-Fehler: " + e.response.data.message;
            } else if (e.message) {
                errorMessage = "Server-Fehler: " + e.message;
            }
            this.toast.error(errorMessage, {
                position: "bottom-center",
                timeout: 5000,
                closeOnClick: true,
            });
            throw e;
        }
    },

    /**
     * Führt eine GET-Abfrage durch
     * 
     * @param {string} URL 
     * @returns {Promise<object>}
     */
    async r_get(URL) {
        try {
            await this.loadStore();
            const headers = this.userStore.currentSessionToken != null ? { 'X-Access-Token': this.userStore.currentSessionToken } : {};
            var ret = await axios.get(apiBaseURL + URL, { headers });
            await validateUIVersion(ret);
            return ret.data;
        } catch (e) {
            throw e;
        }
    },

    /**
     * Führt eine POST-Anfrage durch
     * 
     * @param {string} URL 
     * @param {object} body 
     * @returns {Promise<object>}
     */
    async post(URL, body) {
        try {
            await this.loadStore();
            const headers = this.userStore.currentSessionToken != null ? { 'X-Access-Token': this.userStore.currentSessionToken } : {};
            var ret = await axios.post(apiBaseURL + URL, body, { headers });
            await validateUIVersion(ret);
            return ret.data;
        } catch (e) {
            var errorMessage = "Unbekannter Fehler bei Server-Abfrage.";
            if (e.response && e.response.data && e.response.data.message) {
                errorMessage = "Server-Fehler: " + e.response.data.message;
            } else if (e.message) {
                errorMessage = "Server-Fehler: " + e.message;
            }
            this.toast.error(errorMessage, {
                position: "bottom-center",
                timeout: 5000,
                closeOnClick: true,
            });
            throw e;
        }
    },

    /**
     * Führt eine PUT-Anfrage durch
     * 
     * @param {string} URL 
     * @param {object} body 
     * @returns {Promise<object>}
     */
    async put(URL, body) {
        try {
            await this.loadStore();
            const headers = this.userStore.currentSessionToken != null ? { 'X-Access-Token': this.userStore.currentSessionToken } : {};
            var ret = await axios.put(apiBaseURL + URL, body, { headers });
            await validateUIVersion(ret);
            return ret.data;
        } catch (e) {
            var errorMessage = "Unbekannter Fehler bei Server-Abfrage.";
            if (e.response && e.response.data && e.response.data.message) {
                errorMessage = "Server-Fehler: " + e.response.data.message;
            } else if (e.message) {
                errorMessage = "Server-Fehler: " + e.message;
            }
            this.toast.error(errorMessage, {
                position: "bottom-center",
                timeout: 5000,
                closeOnClick: true,
            });
            throw e;
        }
    },

    /**
     * Führt eine DELETE-Abfrage durch
     * 
     * @param {string} URL 
     * @returns {Promise<object>}
     */
    async delete(URL) {
        try {
            await this.loadStore();
            const headers = this.userStore.currentSessionToken != null ? { 'X-Access-Token': this.userStore.currentSessionToken } : {};
            var ret = await axios.delete(apiBaseURL + URL, { headers });
            await validateUIVersion(ret);
            return ret.data;
        } catch (e) {
            var errorMessage = "Unbekannter Fehler bei Server-Abfrage.";
            if (e.response && e.response.data && e.response.data.message) {
                errorMessage = "Server-Fehler: " + e.response.data.message;
            } else if (e.message) {
                errorMessage = "Server-Fehler: " + e.message;
            }
            this.toast.error(errorMessage, {
                position: "bottom-center",
                timeout: 5000,
                closeOnClick: true,
            });
            throw e;
        }
    },
};

export default exp;