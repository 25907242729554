<script setup>
/**
 * Diese Component wird von den Waagen aufgerufen.
 * Je nachdem, mit welchen GET-Parametern sie aufgerufen wird, zeigt sie hierbei ein anderes
 * Formular an
 * 
 * Bigbag Standardformular (mit Typ = produziert):
 * http://localhost:5173/?WT=1000&TS=2024-04-01_12:00:00&target=x
 * 
 * Bigbag Standardformular (mit Typ = eingekauft)
 * http://localhost:5173/?WT=1000&TS=2024-04-01_12:00:00&target=x&source=purchased
 * 
 * Handaufgabe Standardformular (mit Typ = vor)
 * http://localhost:5173/?WT=1000&TS=2024-04-01_12:00:00&target=x&hatype=before
 * 
 * Handaufgabe Standardformular (mit Typ = nach)
 * http://localhost:5173/?WT=1000&TS=2024-04-01_12:00:00&target=x&hatype=after
 */

import { ref, onMounted } from 'vue';

const type = ref('bigbag');

onMounted(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    type.value = urlParams.get('hatype') && (urlParams.get('hatype') == 'before' || urlParams.get('hatype') == 'after') ? 'ha' : 'bigbag';
});

import BigbagForm from './Bigbag.vue';
import HandaufgabeForm from './Handaufgabe.vue';
</script>
<template>
    <div class="wrapper">
        <div class="container pt-1">
            <BigbagForm v-if="type == 'bigbag'" />
            <HandaufgabeForm v-else-if="type == 'ha'" @submit="type = 'finished'" />
            <div class="card" v-else-if="type == 'finished'">
                <div class="card-body text-center">
                    <h1 class="text-success">
                        <font-awesome-icon icon="fa-solid fa-check"></font-awesome-icon>
                    </h1>
                    <h2 class="text-success mt-4 mb-4">
                        Erfolgreich abgesendet
                    </h2>
                    <p>
                        Die Daten wurden erfolgreich abgesendet, dieses Fenster kann geschlossen werden.
                    </p>
                </div>
            </div>
            <div class="container pt-4" v-if="type != 'finished'">
                <div class="row ps-0 pe-0">
                    <div class="col-6 ps-0">
                        <button class="btn btn-light switch-type" @click="type = 'bigbag'"
                            :class="{ 'active': type == 'bigbag' }">
                            Bigbag
                        </button>
                    </div>
                    <div class="col-6 pe-0">
                        <button class="btn btn-light switch-type" @click="type = 'ha'"
                            :class="{ 'active': type == 'ha' }">
                            Handaufgabe
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.wrapper {
    display: block;
    width: 100dvw;
    height: 100dvh;
    margin: 0;
    padding: 0;
    background-image: url('/src/assets/images/login-bg-tile.png');
    overflow-y: auto;
}

.wrapper .btn.switch-type {
    width: 100%;
    padding: 1em;
    font-size: 18px;
}

.wrapper .btn.switch-type.active {
    color: green;
    background-color: #FFF;
}

#app {
    padding-left: 0 !important;
}
</style>